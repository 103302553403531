import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"


const angajamentSLA = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Hosting Service Legal Agreement - Sitebunker.net"
        pageDescription="Find out here details about the Hosting Service Legal Agreement provided by ServerBunker. Contact us now for complete server options details."
        homePageText="Home"
        homePageUrl="/"
        activePageText="SLA QUALITY AGREEMENT"
      />
      <section className="privacy-policy-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="privacy-policy-content">
                <p>This document represents the SLA QUALITY AGREEMENT provided by EXIM HOST COMPANY, through SiteBunke (<a
                  href="https://www.SiteBunker.net" aria-invalid="true">www.SiteBunker.net</a>).</p>
                <p>Starting March 1, 2021, Sitebunker.net (EXIM HOST COMPANY) guarantees a monthly 99.99% uptime for
                  every provided hosting service. The Uptime is exclusively measured by SiteBunker’s implemented
                  monitoring systems, assured by Uptime Doctor.
                  The SLA Quality Agreement (Service Level Agreement) applies to all SiteBunker’s services.
                </p>
                <p>If the shared hosting, VPS or dedicated server offered by SiteBunker are affected by a downtime or
                  maintenance work that has not been announced in advance (at least 1 hour in advance), we will offer
                  the following non-functionality compensations:
                </p>
                <p>• for a monthly non-functionality between 0 and 59 minutes (approximately 0.1% of the month) - no
                  compensation will be offered</p>
                <p>• for a monthly non-functionality over 59 minutes - for every 1 hour downtime SiteBunker will offer a
                  free service for 1 day (all contracted services will be extended 1 day)
                </p>
                <p>In order to redeem the above listed compensations, the client must submit a request to Sitebunker.net
                  Customer Support Team within 14 days from the reported incident. The reported downtime must be
                  confirmed by SiteBunker’s technical team, before confirming the requested compensation.</p>
                <p>The maximum limit for offered compensation is 1 month (30 days), which will be added free of charge
                  to all services affected by downtime, if reported. Service availability (Uptime) is measured for one
                  month and excludes the previously announced maintenance periods.</p>
                <p>For VPS services or dedicated servers, the contracted service availability (Uptime) is measured by
                  the Ping query of the server response and excludes the situation where the ICMP protocol is turned off
                  in the firewall used by the client.</p>
                <p>Non - functionality compensations are NOT provided if any error or malfunction of the service is
                  caused or generated by:</p>
                <p>• server or network hardware failures<br />• DNS problems or malfunctions registered for DNS servers
                  that are not EXIM HOST property
                  • malfunction of the services due to causes that are not under the control of Sitebunker.net or that
                  cannot be foreseen by Sitebunker.net
                  <br />• interruption of telecommunications (caused by external factors), network attacks, network
                  congestion or other damage, as well as accidental sectioning of at least one optical fiber
                  <br />• natural disasters, earthquakes, wars
                  <br />• unavailability or malfunction of a website, VPS server or dedicated server due to
                  customer-related reasons (website errors, malware, viruses, other computer attacks on the client's
                  website or server) or the software used by it
                  <br />• fire, viruses, hackers, malware attacks
                  <br />• announced maintenance works<br />• improper functionality of an external software (free
                  scripts, e-commerce software or payment processors)
                  <br />• DDoS attacks, Flood, legal authorities actions.</p>
                <p>EXIM HOST COMPANY offers the above mentioned compensations without taking the responsibility for any
                  additional damage, loss of profit, as well as missing opportunities or any other losses suffered by
                  the customer or other third parties implied directly or indirectly.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}
export default angajamentSLA
